import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useNativeInteractor } from '../../module/nativeInteractor/context'
import { QuestionIcon } from '../icons/icon'

export default function Content({
  children,
  surveyParticipated,
}: {
  children: React.ReactNode
  surveyParticipated: boolean
}) {
  const { interactor } = useNativeInteractor()

  const navigate = useNavigate()

  const handleClickSurvey = () => {
    console.log('설문 진행 클릭')

    interactor.amplitudeClickSurveyParticipation(true)

    navigate('/intro-survey')
  }

  return (
    <>
      {!surveyParticipated && <SurveyParticipationBanner onClickTrySurvey={handleClickSurvey} />}
      {children}
    </>
  )
}

function SurveyParticipationBanner({ onClickTrySurvey }: { onClickTrySurvey: () => void }) {
  const { interactor } = useNativeInteractor()

  useEffect(() => {
    interactor.airbridgeRate({ eventCategory: 'airbridge.rate' })
    interactor.amplitudeViewSurveyParticipation()
  }, [])

  return (
    <>
      <div className='relative z-10'>
        <div
          className='absolute w-full bg-white opacity-80'
          style={{ height: 'calc(100vh + 93px)' }}
        ></div>
      </div>
      <div>
        <div className='z-10 absolute flex flex-col items-center w-full justify-center gap-[25px] mt-[50px] tablet-sm:mt-[90px]'>
          <QuestionIcon />
          <div className='font-bold mt-[10px] text-center w-[234px] tablet-sm:w-[370px] text-pretty leading-7 text-[14px] tablet-sm:text-[16px]'>
            <p>
              다른 사람은{' '}
              <span className='whitespace-pre tablet-sm:whitespace-normal'>
                어떤 도서로 공부하고있을까?
              </span>
            </p>
            <p>설문에 참여하시면, 스콘이 알려드릴게요.</p>
          </div>
          <button
            className='text-white text-[14px] bg-black rounded-lg w-[206px] h-[40px]'
            onClick={onClickTrySurvey}
          >
            설문 참여하기
          </button>
        </div>
      </div>
    </>
  )
}
