import {
  type showShareStudyingStatsModalPayload,
  type INativeInteractor,
  type airbridgePayload,
  type amplitudeUpdateDdaySettingsEventProperty,
  type amplitudeUpdateGoalTimeEventProperty,
} from './nativeInteractor'

declare global {
  interface Window {
    callNativeFunction?: CallNativeInterface | null
  }
}

export declare interface CallNativeInterface {
  showSettingModal: () => void
  showSconnChargeModal: () => void
  showShareStudyingStatsModal: (payload: string) => void
  showHoldPointPage: () => void
  showCouponPage: () => void
  showServiceList: () => void
  serviceLogout: () => void
  openUrl: (url: string) => void
  showStoreProduct: (pdfId: string) => void
  getNativeToken: () => Promise<string | undefined>
  requestParentConsent: () => Promise<string | undefined>
  airbridgeRate: (payload: string) => void
  airbridgeFormComplete: (payload: string) => void
  amplitudeViewSurveyParticipation: () => void
  amplitudeClickSurveyParticipation: (surveyParticipation: boolean) => void
  amplitudeCompleteSurveyParticipation: () => void
  amplitudeUpdateDdaySettings: (eventProperty: string) => void
  amplitudeUpdateGoalTime: (eventProperty: string) => void
  amplitudeClickShareAchievement: () => void
}

export class AosNativeInteractor implements INativeInteractor {
  showSettingModal() {
    this.getNativeInteractor()?.showSettingModal()
  }

  showSconnChargeModal() {
    this.getNativeInteractor()?.showSconnChargeModal()
  }

  showShareStudyingStatsModal(payload: showShareStudyingStatsModalPayload) {
    this.getNativeInteractor()?.showShareStudyingStatsModal(JSON.stringify(payload))
  }

  showHoldPointPage() {
    this.getNativeInteractor()?.showHoldPointPage()
  }

  showCouponPage() {
    this.getNativeInteractor()?.showCouponPage()
  }

  serviceLogout() {
    this.getNativeInteractor()?.serviceLogout()
  }

  openUrl(url: string) {
    this.getNativeInteractor()?.openUrl(url)
  }

  showServiceList() {
    this.getNativeInteractor()?.showServiceList()
  }

  airbridgeRate(payload: airbridgePayload) {
    this.getNativeInteractor()?.airbridgeRate(JSON.stringify(payload))
  }

  airbridgeFormComplete(payload: airbridgePayload) {
    this.getNativeInteractor()?.airbridgeFormComplete(JSON.stringify(payload))
  }

  amplitudeViewSurveyParticipation() {
    this.getNativeInteractor()?.amplitudeViewSurveyParticipation()
  }

  amplitudeClickSurveyParticipation(surveyParticipation: boolean) {
    this.getNativeInteractor()?.amplitudeClickSurveyParticipation(surveyParticipation)
  }

  amplitudeCompleteSurveyParticipation() {
    this.getNativeInteractor()?.amplitudeCompleteSurveyParticipation()
  }

  amplitudeUpdateDdaySettings(eventProperty: amplitudeUpdateDdaySettingsEventProperty) {
    this.getNativeInteractor()?.amplitudeUpdateDdaySettings(JSON.stringify(eventProperty))
  }

  amplitudeUpdateGoalTime(eventProperty: amplitudeUpdateGoalTimeEventProperty) {
    this.getNativeInteractor()?.amplitudeUpdateGoalTime(JSON.stringify(eventProperty))
  }

  amplitudeClickShareAchievement() {
    this.getNativeInteractor()?.amplitudeClickShareAchievement()
  }

  async getNativeToken(): Promise<string | undefined> {
    try {
      return await this.requestNativeToken()
    } catch (e) {
      console.log(e)
    }
  }

  showStoreProduct(pdfId: string) {
    this.getNativeInteractor()?.showStoreProduct(pdfId)
  }

  private async requestNativeToken(): Promise<string | undefined> {
    return await new Promise((resolve, reject) => {
      const getToken = (token: string) => {
        resolve(token)
      }

      this.getNativeInteractor()?.getNativeToken()

      window.getNativeToken = getToken
    })
  }

  async requestParentConsent(): Promise<string | undefined> {
    try {
      return await this.requestIsParentConsent()
    } catch (e) {
      console.log(e)
    }
  }

  private async requestIsParentConsent(): Promise<string | undefined> {
    return await new Promise((resolve, reject) => {
      const parentConsent = (isConsented: string | undefined) => {
        resolve(isConsented)
      }

      this.getNativeInteractor()?.requestParentConsent()

      window.requestParentConsent = parentConsent
    })
  }

  private getNativeInteractor(): CallNativeInterface | undefined | null {
    return window?.callNativeFunction ?? window?.top?.callNativeFunction
  }
}
