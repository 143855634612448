import classNames from 'classnames'
import React, { useCallback, useEffect, type FormEvent, type ReactNode } from 'react'
import { useBeforeUnload, useNavigate, useSearchParams } from 'react-router-dom'

import { useBookcafeData } from '../../module/myPage/hook'
import { useNativeInteractor } from '../../module/nativeInteractor/context'
import { usePostSurvey } from '../../module/sconnStudyData/hook'
import { useSurvey, useSurveySteps } from '../../module/survey/hook'
import { type SurveyStep } from '../../module/survey/types'
import { useErrorToast } from '../../util/hooks/useToast'

const modifySearchParams = (currentStep: SurveyStep, searchParams: URLSearchParams) => {
  if (currentStep === 'classroom') {
    searchParams.delete('classroom')
  }
  if (currentStep === 'examinations' || currentStep === 'examinationsCollege') {
    searchParams.delete('exam')
    searchParams.delete('hasUserSearch')
    searchParams.delete('isUniversity')
  }
  if (currentStep === 'year') {
    searchParams.delete('year')
  }
}

export default function Form({
  children,
  onFormLoad,
  onSubmitSuccess,
}: {
  children: ReactNode
  onFormLoad?: () => void
  onSubmitSuccess?: () => void
}) {
  const { interactor } = useNativeInteractor()

  const { user } = useBookcafeData()
  const { mutate, isPending } = usePostSurvey()
  const [searchParams, setSearchParams] = useSearchParams()
  const { surveyOnModal, surveyResult, setSurveyIsPending } = useSurvey()
  const { setErrorMessage, setOpenError, errorToast } = useErrorToast()
  const { steps, activeStep, setSkipFirstStep } = useSurveySteps()
  const currentStep = steps[activeStep]
  const navigate = useNavigate()

  useEffect(() => {
    modifySearchParams(currentStep, searchParams)
    setSearchParams(searchParams)
  }, [])

  useEffect(() => {
    if (!user?.suggestNickName || user?.email !== user?.nickName) {
      setSkipFirstStep(true)

      if (onFormLoad) {
        onFormLoad()
      }
    }
  }, [user?.suggestNickName, user?.nickName])

  useEffect(() => {
    setSurveyIsPending(isPending)
  }, [isPending])

  useBeforeUnload(
    useCallback((e) => {
      e.preventDefault()
      e.returnValue = ''
    }, []),
  )

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    if (currentStep === 'schoolName' || currentStep === 'major') {
      e.preventDefault()
      return
    }

    e.preventDefault()

    mutate(surveyResult, {
      onSuccess: (data) => {
        if (data.result === 'Success') {
          if (onSubmitSuccess) {
            onSubmitSuccess()
          } else {
            navigate('complete')
            if (!surveyOnModal) {
              interactor.airbridgeFormComplete({ eventCategory: 'form.complete' })
              interactor.amplitudeCompleteSurveyParticipation()
            }
          }
        }
      },
      onError: (err) => {
        setOpenError(true)
        setErrorMessage(`설문 제출에 실패했습니다. \n[${err.message}]`) // TODO: 시험이나 학교부터 수정중이었는데 이렇게 될 경우 문제
      },
    })
  }

  return (
    <form
      id='survey-form'
      className={classNames(
        'flex flex-col h-full',
        surveyOnModal
          ? 'ml-[1px] mr-[6px]' /* margin for prevent clipping box-shadow/close-icon by overflow-auto */
          : 'ml-[26px] mr-[25px] tablet-sm:mx-[78px] tablet-md:mx-[80px] tablet-lg:mx-[118px]',
      )}
      onSubmit={handleSubmit}
    >
      {children}
      {errorToast()}
    </form>
  )
}
