import React, { useEffect } from 'react'

import { type TodayStats } from '../api/interfaces/sconnStudyData'
import { type TodayStudyProgress } from '../module/myPage/statsHelper'
import { useUserTodayStatistics } from '../module/sconnStudyData/hook'
import { secondsToHour, secondsToMinute } from '../util/numberUtils'

import {
  BurnIcon,
  CloseIcon,
  EditSMIcon,
  GoalIcon,
  PopoverCenterIcon,
  PopoverRightIcon,
} from './icons/icon'
import TodayDurationProgressBar, {
  TodayDurationProgressBarSkeleton,
} from './TodayDurationProgressBar'

interface GoalOfDaysLayoutProps {
  data: TodayStudyProgress
  openPopover: boolean
  isOverDuration: boolean
  onLoadData: (data: TodayStats) => void
  onClickEdit: () => void
  onClickClosePopover: () => void
}

export function formatDuration(seconds: number) {
  const hours = secondsToHour(seconds)
  const minutes = secondsToMinute(seconds)
  return `${hours !== 0 ? `${hours}시간` : ''}${hours !== 0 && minutes !== 0 ? ' ' : ''}${minutes !== 0 ? `${minutes}분` : ''}`
}

export default function GoalOfDaysLayout(props: GoalOfDaysLayoutProps) {
  const { data } = useUserTodayStatistics()

  useEffect(() => {
    if (data) {
      props.onLoadData(data)
    }
  }, [data])

  return (
    <>
      <div className='flex items-center justify-between'>
        <Header />
        {props.data.continuousDays > 1 && (
          <div>
            <div className='items-center gap-[0.5px] flex visible tablet-sm:invisible'>
              <BurnIcon />
              <span className='text-[#FF8744] text-[12px] font-extrabold'>
                {props.data.continuousDays}일 연속 달성!
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='flex items-center justify-between tablet-sm:justify-normal text-[14px] gap-[6px]'>
        <span className='font-medium'>하루 목표 학습시간</span>
        <div className='relative flex px-[10px] gap-[16px] items-center justify-between rounded-[10px] bg-[#F4F4F4] h-[33px]'>
          <span className='font-extrabold'>
            {props.data.goalDuration === 0 ? '0시간' : formatDuration(props.data.goalDuration)}
          </span>
          <EditSMIcon onClick={props.onClickEdit} />
          {props.openPopover && (
            <div className='absolute top-[-57px] left-[-135px] tablet-sm:left-[-70px] z-10'>
              <div className='relative'>
                <PopoverCenterIcon className='absolute hidden tablet-sm:flex' />
                <PopoverRightIcon className='absolute tablet-sm:hidden' />
                <div className='absolute flex items-center justify-between w-[200px] left-[20px] top-[12px]'>
                  <span className='text-[14px] font-medium'>시험 목표를 설정해 보세요!</span>
                  <CloseIcon onClick={props.onClickClosePopover} />
                </div>
              </div>
            </div>
          )}
        </div>
        {props.data.continuousDays > 1 && (
          <div className='items-center gap-[0.5px] hidden tablet-sm:flex'>
            <BurnIcon />
            <span className='text-[#FF8744] text-[12px] font-extrabold'>
              {props.data.continuousDays}일 연속 달성!
            </span>
          </div>
        )}
      </div>
      <TodayDurationProgressBar
        duration={props.data.duration}
        maxDuration={props.data.goalDuration}
        isOverDuration={props.isOverDuration}
      />
    </>
  )
}

export function GoalOfDaysSkeleton() {
  return (
    <>
      <Header />
      <div className='flex items-center justify-between tablet-sm:justify-normal text-[14px] gap-[6px]'>
        <span className='font-medium'>하루 목표 학습시간</span>
        <div className='animate-pulse relative flex px-[10px] gap-[16px] items-center justify-between rounded-[10px] bg-[#F4F4F4] h-[33px]'>
          <span className='font-extrabold'>0시간</span>
          <EditSMIcon />
        </div>
      </div>
      <TodayDurationProgressBarSkeleton />
    </>
  )
}

function Header() {
  return (
    <div className='flex gap-[10px] items-center'>
      <GoalIcon />
      <span className='font-extrabold text-[14px]'>오늘의 목표</span>
    </div>
  )
}
