import React from 'react'

import DdayBanner from '../components/DdayBanner'
import GoalOfDays from '../components/GoalOfDays'
import GroupRecommendedBooks from '../components/GroupRecommendedBooks'
import MyPageLayout from '../components/myPageLayout'
import StudyingReport from '../components/StudyingReport'
import { useBookcafeData, useMyPage } from '../module/myPage/hook'

export default function MyPageMain() {
  const { user } = useBookcafeData()
  const { surveyParticipated } = useMyPage()

  return (
    <MyPageLayout user={user}>
      <span className='text-xs text-slate-700'>amplitude/airbridge 테스트 2</span>
      <MyPageLayout.AccountNavigation user={user} />
      <MyPageLayout.Content surveyParticipated={surveyParticipated}>
        <DdayBanner />
        <GoalOfDays />
        <StudyingReport />
        <GroupRecommendedBooks />
      </MyPageLayout.Content>
    </MyPageLayout>
  )
}
