import dayjs from 'dayjs'
import React, { lazy, Suspense, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { type Dday } from '../api/interfaces/sconnStudyData'
import { useMyPage } from '../module/myPage/hook'
import { useNativeInteractor } from '../module/nativeInteractor/context'
import { useUpdateUserDday } from '../module/sconnStudyData/hook'
import { useErrorToast } from '../util/hooks/useToast'

import ErrorFallback from './common/ErrorFallback'
import { DdayBannerEditModal } from './DdayBannerEditModal'
import { DdayBannerSkeleton } from './DdayBannerLayout'

const DdayBannerLayout = lazy(async () => await import('./DdayBannerLayout'))

function getDiffDays(start: number, end: number) {
  const startDate = dayjs(start).startOf('day')
  const endDate = dayjs(end).startOf('day')
  return endDate.diff(startDate, 'days')
}

export const defaultName = '준비중인 시험명을 적어주세요'
export const defaultMessage = '스콘이 항상 너를 응원할게!'
const defaultDiffDate = 365

export default function DdayBanner() {
  const { interactor } = useNativeInteractor()

  const { setErrorMessage, setOpenError, errorToast } = useErrorToast()
  const { mutate, isPending } = useUpdateUserDday()
  const { surveyParticipated } = useMyPage()

  const [openEdit, setOpenEdit] = useState<boolean>(false)

  const today = new Date().getTime()

  const defaultDdayData = {
    name: '',
    examId: null,
    message: defaultMessage,
    timeStamp: new Date().setFullYear(new Date().getFullYear() + 1),
    showMessage: true,
  }

  const [ddayData, setDdayData] = useState<Dday>(defaultDdayData)
  const [selectedDdayData, setSelectedDdayData] = useState<Dday>(defaultDdayData)
  const [diffDate, setDiffDate] = useState<number>(defaultDiffDate)

  return (
    <>
      {surveyParticipated && (
        <>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<DdayBannerSkeleton />}>
              <DdayBannerLayout
                data={selectedDdayData}
                diffDate={diffDate}
                onClickEdit={() => {
                  setOpenEdit(true)
                }}
                onLoadData={(data: Dday) => {
                  setDdayData({ ...data, timeStamp: data.timeStamp * 1000 })
                  setSelectedDdayData({ ...data, timeStamp: data.timeStamp * 1000 })
                  setDiffDate(getDiffDays(today, data.timeStamp * 1000))
                }}
              />
            </Suspense>
          </ErrorBoundary>
          <DdayBannerEditModal
            open={openEdit}
            data={{
              certName: ddayData.name,
              ddayTimeStamp: ddayData.timeStamp,
              message: ddayData.message ?? '',
              hideMessage: !ddayData.showMessage,
              onChangeCertName: (e) => {
                setDdayData({ ...ddayData, name: e.target.value })
              },
              onChangeMessage: (e) => {
                setDdayData({ ...ddayData, message: e.target.value })
              },
              onChangeHideMessage: (e) => {
                setDdayData({ ...ddayData, showMessage: !e.target.checked })
              },
              onClickCheckedCheckboxHideMessage: () => {
                setDdayData({ ...ddayData, showMessage: true })
              },
            }}
            loadingOnOk={isPending ? 'true' : 'false'}
            disabledOnOk={ddayData.name.trim().length === 0}
            onCancel={() => {
              setDdayData(selectedDdayData)
              setOpenEdit(false)
            }}
            onOk={(saveDate) => {
              const message =
                ddayData.showMessage &&
                (ddayData.message === null || ddayData.message.trim().length === 0)
                  ? defaultMessage
                  : ddayData.message
              const timeStamp = removeTimeFromTimestamp(saveDate) / 1000
              mutate(
                {
                  ...ddayData,
                  message,
                  timeStamp,
                },
                {
                  onSuccess: (data) => {
                    if (data.result === 'Success') {
                      setOpenEdit(false)
                      setSelectedDdayData({ ...ddayData, message, timeStamp })
                      setDiffDate(getDiffDays(today, saveDate))

                      const date = new Date(timeStamp * 1000)
                      const formattedDate = new Intl.DateTimeFormat('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }).format(date)
                      interactor.amplitudeUpdateDdaySettings({
                        dday_title: ddayData.name,
                        dday_date: formattedDate,
                      })
                    }
                  },
                  onError: (err) => {
                    setOpenError(true)
                    setErrorMessage(`정보 등록에 실패했습니다. \n[${err.message}]`)
                  },
                },
              )
            }}
          />
          {errorToast()}
        </>
      )}
    </>
  )
}

function removeTimeFromTimestamp(timestamp: number) {
  const date = new Date(timestamp)
  date.setHours(0, 0, 0, 0)
  return date.getTime()
}
