import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useBookcafeData, useStudyingReport } from '../module/myPage/hook'
import { formatDurationToText } from '../module/myPage/statsHelper'
import { useNativeInteractor } from '../module/nativeInteractor/context'
import { type showShareStudyingStatsModalPayload } from '../module/nativeInteractor/nativeInteractor'
import { type CustomDate } from '../util/CustomDate'

const periodFunctions = {
  monthly: (date: CustomDate | null) => date?.toShareMonthRange(),
  weekly: (date: CustomDate | null) => date?.toShareWeekRange(),
  daily: (date: CustomDate | null) => date?.toYearMonthDay('.'),
}

function StudyingTime() {
  const { interactor } = useNativeInteractor()
  const { user } = useBookcafeData()
  const {
    statsDuration,
    isToday,
    statsSelectedDate,
    statsDisplayContents,
    statsEndDate,
    statsPeriod,
    noContents,
  } = useStudyingReport()
  const [displayTime, setDisplayTime] = useState<string>('0분')

  useEffect(() => {
    setDisplayTime(formatDurationToText(statsDuration))
  }, [statsDuration])

  const handleClickShare = () => {
    if (!user) return

    const period = periodFunctions[statsPeriod](
      statsPeriod === 'weekly' ? statsEndDate : statsSelectedDate,
    )

    const payload: showShareStudyingStatsModalPayload = {
      nickname: user.nickName || user.email,
      period: period ?? 'undefined',
      studyTime: displayTime,
      contents: statsDisplayContents,
    }
    console.log('Share studying stats: ', JSON.stringify(payload))
    interactor.showShareStudyingStatsModal(payload)
    interactor.amplitudeClickShareAchievement()
  }

  return (
    <div className='gap-[20px] flex-col tablet-sm:flex-row text-[13px] tablet-sm:text-[14px] font-medium flex justify-center tablet-sm:justify-between items-center rounded-[10px] bg-[#FFF4DD] tablet-sm:h-[67px] h-[138px] px-[30px] mt-[8px]'>
      <span>
        위 기간동안{' '}
        <span className='z-20 text-decoration__underline--mid-layer font-extrabold text-[16px]'>
          {displayTime}
        </span>{' '}
        학습했어요.
      </span>
      <button
        className={classNames(
          'text-[14px] w-full h-[46px] text-white rounded-[10px] tablet-sm:w-[143px] tablet-sm:h-[33px]',
          isToday || noContents ? 'bg-button-disabled' : 'bg-black',
        )}
        disabled={isToday || noContents}
        onClick={handleClickShare}
      >
        친구에게 자랑하기
      </button>
    </div>
  )
}

export default StudyingTime
